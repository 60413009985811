import React,{useEffect,useState} from 'react';
import { Formik } from 'formik';
import ProfileSchema from './validacion';
import Servicio from "../service/consultas"
import { Modal, Button } from 'react-bootstrap';

class Formulario extends React.Component {
  constructor(props) {
    super(props);
    this.reffooter = React.createRef();
    this.state={
      show:false
    }
    this.handleClose=this.handleClose.bind(this);
    this.handleShow =this.handleShow.bind(this);
  }
    

    handleClose() {
      console.log('handleClose')
      this.setState({show:false})
    };
     handleShow() {

       console.log('handleShow')
      this.setState({show:true})
    };
     
    componentDidUpdate(){
     
      if(this.props.posicionFooter)this.reffooter.current.focus()
    }
    componentDidMount(){
   
      if(this.props.posicionFooter)this.reffooter.current.focus()
    }
     render(){
      return(
        <div className="mx-5 formik-font"   tabIndex="1">
          <h4>Hacenos tu consulta</h4>
       <Formik
         initialValues={{ fullname: '', email: '', texto: '',seguridad:'' }}
         validationSchema={ProfileSchema}
         onSubmit={(values, { setSubmitting }) => {
           setTimeout(async() => {
             const res = await Servicio.enviar(values)
             
             if(res){
              this.handleShow()
              values.email='';
              values.fullname='';
              values.texto='';
             }
             
             console.log(res);
             console.log(values.email);
             setSubmitting(false);
           }, 1000);
         }}
       >
         {({
           values,
           errors,
           touched,
           handleChange,
           handleBlur,
           handleSubmit,
           isSubmitting
         }) => (
          
           <form onSubmit={handleSubmit}>
             <div className="form-group">
               {/*<label htmlFor="fullnamme">Nombre </label>*/}
               <input
                placeholder="Nombre"
                ref={this.reffooter}
                 type="fullname"
                 name="fullname"
                 onChange={handleChange}
                 onBlur={handleBlur}
                 value={values.fullname}
                 style={{width:"100%"}}
               />
               <p style={{color:"red"}}>{errors.fullname && touched.fullname && errors.fullname}</p>
             </div>
             <div className="form-group">
               {/*<label htmlFor="email">Email</label>*/}
               <input
                placeholder="Email"
                 type="email"
                 name="email"
                 onChange={handleChange}
                 onBlur={handleBlur}
                 value={values.email}
                 style={{width:"100%"}}
               />
               <p style={{color:"red"}}>{errors.email && touched.email && errors.email}</p>
             </div>
             <div className="form-group">
               {/*<label htmlFor="texto">Consulta</label>*/}
               <textarea
               placeholder="Consulta"
                 type="text"
                 name="texto"
                 onChange={handleChange}
                 onBlur={handleBlur}
                 value={values.texto}
                 style={{width:"100%"}}
               />
               <p style={{color:"red"}}>{errors.texto && touched.texto && errors.texto}</p>
             </div>
             <div className="form-group" hidden="true">
               <label htmlFor="seguridad">Seguridad</label>
               <textarea
                 type="seguridad"
                 name="seguridad"
                 onChange={handleChange}
                 onBlur={handleBlur}
                 value={values.seguridad}
                 style={{width:"100%"}}
               />
               <p style={{color:"red"}}>{errors.seguridad && touched.seguridad && errors.seguridad}</p>
             </div>
             <button 
             type="submit"
              disabled={isSubmitting}
              style={{width:"100%", background: "rgba(153, 198, 187, 0.89)"}}
              >
               {isSubmitting ? 'Enviando' : 'Enviar'}
             </button>
           </form>
         )}
       </Formik>
       
       <Modal show={this.state.show} onHide={this.handleClose}>
           <Modal.Header closeButton>
             <Modal.Title>Consulta</Modal.Title>
           </Modal.Header>
           <Modal.Body>Su consulta se envio con exito</Modal.Body>
           <Modal.Footer>
             <Button variant="success" onClick={this.handleClose}>
               Cerrar
             </Button>
             
           </Modal.Footer>
         </Modal>
     </div>
     )
     }
    
 
    };

export default Formulario;