import React from 'react';

import facebook from "../assest/imagenes/face.png";

import instagram from "../assest/imagenes/instagram.png";

import '../App.css';




class  Redes extends React.Component {
    constructor(props){
        super(props);
       
        this.state = {
            radio:true,
           
          };
            
   }
  

    render (){
        return(
            <>
            <a class="btn-icon active text-secondary " href="https://www.facebook.com/turismosanantoniodeareco/" target="_blank" rel="noopener noreferrer">
            <img className="" alt='' src={facebook} width="50" height="50" />{' '}
            </a>
            <a class="btn-icon active text-secondary " href="https://www.instagram.com/turismoareco/" target="_blank" rel="noopener noreferrer" >
            <img className="" alt='' src={instagram} width="50" height="50" />{' '}
            </a>
            
           
            
             
            </>
        )
    }


}
export default Redes;