import React from 'react';

import NavAdmin from './components/NavAdmin';
import {Container} from 'react-bootstrap';
import Footer from './Footer';
import './App.css';


const  App=()=> {
  return (
    <div className="fluid">
     
     <NavAdmin/>
    
   
     
    </div>
  );
}

export default App;
