import React from 'react';
import { Col, Row } from 'react-bootstrap';
import logo from './assest/imagenes/Logo Turismo-01.WebP';
import {  Link } from "react-router-dom";
import Redes from './components/Redes'
import Formulario from './components/formulario';
import bannersello from './assest/imagenes/sello120x120.WebP';
import logomuni from './assest/imagenes/logomuni.WebP';
import './App.css';

const Footer = (props)=>{
    
    return(
        <>
        <div className="footer-margin footer-grande" >
        
         <Row>
            <Col md={4}  >
                <Link to='/home'>
                <div>
                <Row className="footer-col1" >
                   
                <img
                        alt=""
                        src={logo}
                        width="80"
                        height="80"
                        className="menu-logo"
                        style={{marginTop:'10px'}}
                        
                    /> 
                
                <h5 className="footer-titulo1 " >San Antonio de Areco</h5>
                </Row>
                <Row className="footer-col1" >
                   
                   <img
                           alt=""
                           src={bannersello}
                           width="80"
                           height="80"
                           className="menu-logo"
                           style={{marginTop:'10px'}}
                           
                       /> 
                   
                   <h5 className="footer-titulo1 " >San Antonio de Areco</h5>
                   </Row>
                   <Row className="footer-col1" >
                   
                   <img
                           alt=""
                           src={logomuni}
                           width="80"
                           height="80"
                           className="menu-logo"
                           style={{marginTop:'10px'}}
                           
                       /> 
                   
                   <h5 className="footer-titulo1 " >San Antonio de Areco</h5>
                   </Row>
                   </div>
                </Link>
            </Col>
            <Col md={4} style={{fontFamily:"Rocoto, sans-serif"}}>
                <h3 className="footer-text-grande" style={{marginTop : 10}}>Dirección de turismo</h3>
                <h3 className="footer-text-grande" style={{marginTop : 10}}>Bv. Zerboni y Arellano – San Antonio de Areco </h3>
                <h3 className="footer-text-grande">Tel.: (02326) 453165</h3>
                <h3 className="footer-text-grande" >
                    <a style={{color: '#000000'}}  href="mailto:turismo@areco.gob.ar">turismo@areco.gob.ar</a>
                </h3>
                <Redes></Redes>
            </Col>
            <Col md={4} >
                 <Formulario posicionFooter={props.posicion1}/>
               
            </Col>
         </Row>    
         </div>

         <div className="footer-margin footer-pequenio" >
         
            <Col lg={4}  >
            
                <Row className="footer-col1">
                    
                <div className="footer-centrado " hidden={true}>
                <img
                        alt=""
                        src={logo}
                        width="70"
                        height="90"
                        className="logo-footer"
                    /> 
                </div>
               

                <div className="footer-redes footer-centrado">
                <Redes></Redes>
                </div>
                 
                </Row>
            </Col>
            <Col lg={4} style={{fontFamily:"Rocoto, sans-serif"}} >
                <h3 className="footer-text">Dirección de turismo</h3>
                <h3 className="footer-text">Bv. Zerboni y Arellano – San Antonio de Areco</h3>
                <h3 className="footer-text">Tel.: (02326) 453165</h3>
                <h3 className="footer-text" >
                <a style={{color: '#000000'}}  href="mailto:turismo@areco.gob.ar">turismo@areco.gob.ar</a>
                </h3>
                <Formulario posicionFooter={props.posicion1}/>
            </Col>
          
           
         </div>
         </>
    );
    
    }
    export default Footer;