

import React, { Suspense, lazy, useRef }  from 'react';

import { Switch, Route, Link } from "react-router-dom";
import {NavDropdown,Navbar,Nav,Dropdown ,NavItem,NavLink,DropdownButton, Container }from "react-bootstrap";
import logo from '../assest/imagenes/Logo Turismo-02.WebP';
import sello from '../assest/imagenes/sello62x62.WebP';
import Placeholder from './Placeholder';
import { LinkContainer } from 'react-router-bootstrap';
import ScrollToTop from './ScrollToTop';
import { useState } from 'react';
import { useEffect } from 'react';

const Home = lazy(() =>import ('../Home'));
const gastronomia = lazy(() =>import ('../Gastronomia'));
const Footer = lazy(() =>import ('../Footer'));
const Ubicacion = lazy(() =>import ('../Ubicacion'));
const fiesta = lazy(() =>import ('../fiesta'));
const patronales = lazy(() =>import ('../patronales'));
const Naturaleza = lazy(() =>import ('../Naturaleza'));

const Costanera = lazy(() =>import ('../Costanera'));
const Museos = lazy(() =>import ('../Museos'));
const VisitasGuiadas = lazy(() =>import ('../VisitasGuiadas'));
const EventosCulturales = lazy(() =>import ('../EventosCulturales'));
const Artesanos = lazy(() =>import ('../Artesanos'));
const Vagues = lazy(() =>import ('../Vagues'));
const VillaLia = lazy(() =>import ('../VillaLia'));
const Duggan = lazy(() =>import ('../Duggan'));
const Vivilatradicion = lazy(() =>import ('../Vivilatradicion'));
const LaCiudad = lazy(() =>import ('../Laciudad'));
const GuiaHoteles = lazy(() =>import ('../GuiaHoteles'));
const GuiaHostel = lazy(() =>import ('../GuiaHostel'));
const GuiaCasas = lazy(() =>import ('../GuiaCasas'));
const GuiaRural = lazy(() =>import ('../GuiaRural'));
const GuiaBED= lazy(() =>import ('../GuiaBED&BREAKFAST'));
const GuiaCamping= lazy(() =>import ('../GuiaCamping'));
const GuiaVillaLia= lazy(() =>import ('../GuiaVillaLia'));
const GuiaVagues= lazy(() =>import ('../GuiaVagues')) ;
const GuiaDuggan= lazy(() =>import ('../GuiaDuggan'));
const GuiaRestaurante= lazy(() =>import ('../GuiaRestaurante'));
const GuiaPizzeria= lazy(() =>import ('../GuiaPizzeria'));
const GuiaBar= lazy(() =>import ('../GuiaBar'));
const GuiaCafeteria= lazy(() =>import ('../GuiaCafeteria'));
const GuiaBoliches= lazy(() =>import ('../GuiaBoliches'));
const GuiaDelivery= lazy(() =>import ('../GuiaDelivery'));
const GuiaCervecerias= lazy(() =>import ('../GuiaCervecerias'));
const GuiaRegionales= lazy(() =>import ('../GuiaRegionales'));
const GuiaCabalgatas= lazy(() =>import ('../GuiaCabalgatas'));
const GuiaPlateria= lazy(() =>import ('../GuiaPlateria'));
const GuiaTalabarteria= lazy(() =>import ('../GuiaTalabarteria'));
const GuiaSogueria= lazy(() =>import ('../GuiaSogueria'));
const GuiaTelar= lazy(() =>import ('../GuiaTelar'));
const GuiaCeramica= lazy(() =>import ('../GuiaCeramica'));
const GuiaJoyeria= lazy(() =>import ('../GuiaJoyeria'));
const GuiaAnticuarios= lazy(() =>import ('../GuiaAnticuarios'));
const GuiaTurismo= lazy(() =>import ('../GuiaTurismo'));
const Agenda= lazy(() =>import ('../Agenda'));
const GuiaRegionalesTalabarteria= lazy(() =>import ('../GuiaRegionalesTalabarteria'));
const NavAdmin=()=>{
  const[posicion,setPosicion]=useState(false)
  const[posicionFooter,setPosicionFooter]=useState(false)
  const inputRef = useRef(null);
 useEffect(() => {
    console.log(inputRef.current.className);
    
  }, []);

  const onButtonClickFooter = () => {
    setPosicionFooter(true)
    console.log("onButtonClickFooter"+posicionFooter)
  };
  useEffect(() => {
    if(posicionFooter)setPosicionFooter(false)
   
  }, [posicionFooter])

  const onButtonClick = () => {
    setPosicion(true)
    
  };
  useEffect(() => {
    if(posicion)setPosicion(false)
   
  }, [posicion])

 
    
  
    return(
        <div >
        
        <Navbar  expand="lg"  sticky ="top" variant="dark">
        <LinkContainer to="/home" className="link">
        <Navbar.Brand >
          <img alt="logo" src={logo} height="70px" width="70px" />
         </Navbar.Brand >
         </LinkContainer>
           {/*<LinkContainer to="/home" className="link">
          <Navbar.Brand  onClick={onButtonClick}>
          
            <img alt="logo" src={sello} height="70px" width="70px"/>
            
    </Navbar.Brand>
           </LinkContainer>*/}
        <Navbar.Toggle aria-controls="basic-navbar-nav" ref={inputRef} />
        <Navbar.Collapse id="basic-navbar-nav" >
          <Nav className="mr-auto"variant="pills" defaultActiveKey="/home">
              <LinkContainer to="/home" className="link">
              <Nav.Link eventKey="1" >Inicio</Nav.Link>
              </LinkContainer>
            < Dropdown  as = { NavItem } > 
              < Dropdown.Toggle  as = { NavLink }  >  Historia y Tradición </ Dropdown.Toggle > 
              < Dropdown.Menu >
              <LinkContainer to="/laciudad" className="link">
                  <Dropdown.Item eventKey="2.6">La Ciudad</Dropdown.Item>
                  </LinkContainer>
                  <LinkContainer to="/fiesta" className="link">
                  <Dropdown.Item eventKey="2.2">Fiesta de la Tradición</Dropdown.Item>
                  </LinkContainer>
                  <LinkContainer to="/patronales" className="link">
                  <Dropdown.Item eventKey="2.3">Fiestas Patronales</Dropdown.Item>
                  </LinkContainer>
                  <LinkContainer to="/artesanos" className="link">  
                  <Dropdown.Item eventKey="2.5" >Artesanos</Dropdown.Item>
                  </LinkContainer>
                  <LinkContainer to="/vivilatradicion" className="link">
                  <Dropdown.Item eventKey="2.4">Viví la tradición</Dropdown.Item>
                  </LinkContainer>
                  
                  
              </ Dropdown.Menu > 
            </ Dropdown > 
            {/*<LinkContainer to="/visitasguiadas" className="link"> 
            <Nav.Link eventKey="2b9" className="link">Visitas Guiadas</Nav.Link>
            </LinkContainer>*/}
            < Dropdown  as = { NavItem } > 
              < Dropdown.Toggle  as = { NavLink }>  Atractivos </ Dropdown.Toggle > 
              < Dropdown.Menu > 
              <  DropdownButton 
                       as={NavItem}
                       key='right'
                       
                       drop='right'
                       variant="secondary"
                       title="Naturaleza"

                    > 
                      <LinkContainer to="/naturaleza" className="link">
                      <NavDropdown.Item eventKey="6.1" >Reserva Natural</NavDropdown.Item>
                      </LinkContainer>
                      <LinkContainer to="/costanera" className="link">
                      <NavDropdown.Item eventKey="6.2">Costanera</NavDropdown.Item>
                      </LinkContainer>
                    </DropdownButton>
                    <LinkContainer to="/gastronomia" className="link">
                  <Dropdown.Item eventKey="3.3" >Gastronomía</Dropdown.Item>
                     </LinkContainer>
                    <  DropdownButton 
                       as={NavItem}
                       key='right'
                       
                       drop='right'
                       variant="secondary"
                       title="Cultura"

                    > 
                      <LinkContainer to="/museos" className="link">
                      <NavDropdown.Item eventKey="5.1" >Circuito de Museos</NavDropdown.Item>
                      </LinkContainer>
                      
                      <LinkContainer to="/eventosculturales" className="link">
                      <NavDropdown.Item eventKey="5.3">Eventos Culturales</NavDropdown.Item>
                      </LinkContainer>
                    </DropdownButton>
                    <  DropdownButton 
                       as={NavItem}
                       key='right'
                       
                       drop='right'
                       variant="secondary"
                       title="Pueblos Rurales"

                    > 
                      <LinkContainer to="/vagues" className="link">
                      <NavDropdown.Item eventKey="8.1" >Vagues</NavDropdown.Item>
                      </LinkContainer>
                      <LinkContainer to="/villalia" className="link">
                      <NavDropdown.Item eventKey="8.2">Villa Lía</NavDropdown.Item>
                      </LinkContainer>
                      <LinkContainer to="/duggan" className="link">
                      <NavDropdown.Item eventKey="8.3">Duggan</NavDropdown.Item>
                      </LinkContainer>
                    </DropdownButton>
                  
              </ Dropdown.Menu > 
            </ Dropdown > 
            {/*<LinkContainer to="/ubicacion" className="link"> 
            <Nav.Link eventKey="4" className="link">Cómo llego</Nav.Link>
            </LinkContainer>
            <LinkContainer to="/agenda" className="link" > 
            <Nav.Link eventKey="5" className="link">Qué Hacer</Nav.Link>
          </LinkContainer>*/}
            
            < Dropdown  as = { NavItem } > 
            < Dropdown.Toggle  as = { NavLink }> Guía de Servicios </ Dropdown.Toggle >
            < Dropdown.Menu > 
              <  DropdownButton 
                       as={NavItem}
                       key='right'
                       
                       drop='right'
                       variant="secondary"
                       title="Alojamiento"

                    >
                      <LinkContainer to="/guia" className="link">
                      <NavDropdown.Item eventKey="7.1" >Hoteles</NavDropdown.Item>
                      </LinkContainer>
                      <LinkContainer to="/casas" className="link">
                      <NavDropdown.Item eventKey="7.3" >Casas | Cabañas Departamentos | Posadas</NavDropdown.Item>
                      </LinkContainer>
                      <LinkContainer to="/hostel" className="link">
                      <NavDropdown.Item eventKey="7.2" >Hostel</NavDropdown.Item>
                      </LinkContainer>
                      <LinkContainer to="/rural" className="link">
                      <NavDropdown.Item eventKey="7.4" >Alojamiento Turístico Rural</NavDropdown.Item>
                      </LinkContainer>
                      <LinkContainer to="/bed" className="link">
                      <NavDropdown.Item eventKey="7.5" >Bed & Breakfast</NavDropdown.Item>
                      </LinkContainer>
                      <LinkContainer to="/camping" className="link">
                      <NavDropdown.Item eventKey="7.6" >Camping</NavDropdown.Item>
                      </LinkContainer>
                      <LinkContainer to="/guiavillalia" className="link">
                      <NavDropdown.Item eventKey="7.7" >Alojamiento en Villa Lía</NavDropdown.Item>
                      </LinkContainer>
                      <LinkContainer to="/guiavagues" className="link">
                      <NavDropdown.Item eventKey="7.8" >Alojamiento en Vagues</NavDropdown.Item>
                      </LinkContainer>
                      <LinkContainer to="/guiaduggan" className="link">
                      <NavDropdown.Item eventKey="7.9" >Alojamiento en Duggan</NavDropdown.Item>
                      </LinkContainer>
               </DropdownButton>
               <  DropdownButton 
                       as={NavItem}
                       key='right'
                       
                       drop='right'
                       variant="secondary"
                       title="Gastronomía"

                    >
                      <LinkContainer to="/restaurante" className="link">
                      <NavDropdown.Item eventKey="8.1" >Restaurante | Parrilla</NavDropdown.Item>
                      </LinkContainer>
                      <LinkContainer to="/pizzeria" className="link">
                      <NavDropdown.Item eventKey="8.3" >Pizzerías</NavDropdown.Item>
                      </LinkContainer>
                      <LinkContainer to="/bar" className="link">
                      <NavDropdown.Item eventKey="8.2" >Resto | Bar</NavDropdown.Item>
                      </LinkContainer>
                      <LinkContainer to="/cafeteria" className="link">
                      <NavDropdown.Item eventKey="8.4" >Cafetería | Pastelería</NavDropdown.Item>
                      </LinkContainer>
                      <LinkContainer to="/boliches" className="link">
                      <NavDropdown.Item eventKey="8.5" >Boliches antiguos | Bares de campo</NavDropdown.Item>
                      </LinkContainer>
                      <LinkContainer to="/cervecerias" className="link">
                      <NavDropdown.Item eventKey="8.8" >Bares | Cervecerías</NavDropdown.Item>
                      </LinkContainer>
                      <LinkContainer to="/rapidas" className="link">
                      <NavDropdown.Item eventKey="8.6" >Comidas rápidas y para llevar </NavDropdown.Item>
                      </LinkContainer>
                      <LinkContainer to="/regionales" className="link">
                      <NavDropdown.Item eventKey="8.7" >Productos Regionales</NavDropdown.Item>
                      </LinkContainer>
                </DropdownButton>
                <  DropdownButton 
                       as={NavItem}
                       key='right'
                       
                       drop='right'
                       variant="secondary"
                       title="Artesanos"

                    >
                      <LinkContainer to="/guiaplateria" className="link">
                      <NavDropdown.Item eventKey="11.1" >Platería</NavDropdown.Item>
                      </LinkContainer>
                      <LinkContainer to="/guiatalabarteria" className="link">
                      <NavDropdown.Item eventKey="11.3" >Talabartería</NavDropdown.Item>
                      </LinkContainer>
                      <LinkContainer to="/guiaregionalestalabarteria" className="link">
                      <NavDropdown.Item eventKey="11.2" >Regionales | Talabartería</NavDropdown.Item>
                      </LinkContainer>
                      <LinkContainer to="/guiasogueria" className="link">
                      <NavDropdown.Item eventKey="11.4" >Soguería</NavDropdown.Item>
                      </LinkContainer>
                      <LinkContainer to="/guiatelar" className="link">
                      <NavDropdown.Item eventKey="11.5" >Telar</NavDropdown.Item>
                      </LinkContainer>
                      <LinkContainer to="/guiaceramica" className="link">
                      <NavDropdown.Item eventKey="11.6" >Cerámica</NavDropdown.Item>
                      </LinkContainer>
                      <LinkContainer to="/guiajoyeriacontemporanea" className="link">
                      <NavDropdown.Item eventKey="11.7" >Joyería Contemporánea</NavDropdown.Item>
                      </LinkContainer>
                      <LinkContainer to="/guiaanticuarios" className="link">
                      <NavDropdown.Item eventKey="11.8" >Anticuarios</NavDropdown.Item>
                      </LinkContainer>
                      
                </DropdownButton>
                       
                      <LinkContainer to="/cabalgatas" className="link">
                      <NavDropdown.Item eventKey="10.1" >Cabagaltas | Día de campo</NavDropdown.Item>
                      </LinkContainer>
                      <LinkContainer to="/guiadeturismo">
                      <NavDropdown.Item eventKey="12.1" >Guías de turismo</NavDropdown.Item>
                      </LinkContainer>  
                      <NavDropdown.Item eventKey="14.1" href="https://sanantoniodeareco.tur.ar/servicios.pdf" target="_blank" className="link">Guía completa en Pdf</NavDropdown.Item>      
                  </Dropdown.Menu> 
                 </Dropdown>
                 <LinkContainer to="/ubicacion" className="link"> 
                  <Nav.Link eventKey="4" className="link">Cómo llego</Nav.Link>
                  </LinkContainer>
                 <Nav.Link eventKey="3a11" className="link" onClick={onButtonClickFooter}>Contacto</Nav.Link>
          </Nav>
       
        </Navbar.Collapse>
      </Navbar>
     
      
      <Suspense fallback={<Placeholder/>}>
     <ScrollToTop />
      <Switch>
      <Route exact path="/" ><Home posicion={posicion}/></Route>
      <Route  path="/home"><Home posicion={posicion}/></Route>
      <Container>
      <Route  path="/fiesta" component={fiesta}/>
      <Route path="/patronales" component={patronales}/>
      <Route  path="/Naturaleza" component={Naturaleza}/>
      <Route  path="/duggan" component={Duggan}/>
      <Route  path="/villalia" component={VillaLia}/>
      <Route  path="/vagues" component={Vagues}/>
      <Route  path="/ubicacion" component={Ubicacion}/>
      <Route  path="/costanera" component={Costanera}/>
      <Route  path="/gastronomia" component={gastronomia}/>
      <Route  path="/museos" component={Museos}/>
      <Route  path="/visitasguiadas" component={VisitasGuiadas}/>
      <Route  path="/EventosCulturales" component={EventosCulturales}/>
      <Route  path="/artesanos" component={Artesanos}/>
      <Route  path="/laciudad" component={LaCiudad}/>
      <Route  path="/vivilatradicion" component={Vivilatradicion}/>
      <Route  path="/guia" component={GuiaHoteles}/>
      <Route  path="/casas" component={GuiaCasas}/>
      <Route  path="/hostel" component={GuiaHostel}/>
      <Route  path="/rural" component={GuiaRural}/>
      <Route  path="/bed" component={GuiaBED}/>
      <Route  path="/camping" component={GuiaCamping}/>
      <Route  path="/guiavillalia" component={GuiaVillaLia}/>
      <Route  path="/guiavagues" component={GuiaVagues}/>
      <Route  path="/guiaduggan" component={GuiaDuggan}/>
      <Route  path="/restaurante" component={GuiaRestaurante}/>
      <Route  path="/pizzeria" component={GuiaPizzeria}/>
      <Route  path="/bar" component={GuiaBar}/>
      <Route  path="/cafeteria" component={GuiaCafeteria}/>
      <Route  path="/boliches" component={GuiaBoliches}/>
      <Route  path="/rapidas" component={GuiaDelivery}/>
      <Route  path="/cervecerias" component={GuiaCervecerias}/>
      <Route  path="/regionales" component={GuiaRegionales}/>
      <Route  path="/cabalgatas" component={GuiaCabalgatas}/>
      <Route  path="/guiaplateria" component={GuiaPlateria}/>
      <Route  path="/guiatalabarteria" component={GuiaTalabarteria}/>
      <Route  path="/guiasogueria" component={GuiaSogueria}/>
      <Route  path="/guiatelar" component={GuiaTelar}/>
      <Route  path="/guiaceramica" component={GuiaCeramica}/>
      <Route  path="/guiajoyeriacontemporanea" component={GuiaJoyeria}/>
      <Route  path="/guiaanticuarios" component={GuiaAnticuarios}/>
      <Route  path="/guiadeturismo" component={GuiaTurismo}/>
      <Route  path="/guiaregionalestalabarteria" component={GuiaRegionalesTalabarteria}/>
      <Route  path="/agenda" component={Agenda}/>
      </Container>
      </Switch>
      <Footer posicion1={posicionFooter}/>
      </Suspense>
      
</div>

  
      ) 
    
}
export default NavAdmin;